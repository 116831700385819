import React, { useState, useRef, useEffect } from "react";
import ReactPlayer from "react-player";
import { Box } from "@mui/material";

import { BoltPlayerState } from "../../types";
import { FlexRow } from "../FlexRow";
import { LoadingView } from "../LoadingView";
import PlayerControls from "./PlayerControls";
import StreamOffline from "../../assets/pngs/stream_offline.png";
import { createOtteraChannelTrackUrl } from "../../utils/formatter";

type Props = {
  url?: string;
  channelId?: string;
  title?: string;
  isMuted?: boolean;
  isLive?: boolean;
  controls?: boolean;
  isPopup?: boolean;
  isSideChatBoxOpen?: boolean;
  isFloatingPlayer?: boolean;
  className?: string;
  handlePopout?: () => void;
  handleChatClose?: () => void;
  closeFloatingPlayer?: () => void;
  backToProfile?: () => void;
};

const BoltPlayer = ({
  url,
  channelId,
  title,
  isMuted = true,
  isLive = true,
  controls = true,
  isPopup = false,
  isSideChatBoxOpen,
  isFloatingPlayer = false,
  className = "",
  handlePopout,
  handleChatClose,
  closeFloatingPlayer,
  backToProfile,
}: Props) => {
  const playerRef = useRef<ReactPlayer>(null);
  const [loading, setLoading] = useState(isLive);
  const [playing, setPlaying] = useState(true);
  const [muted, setMuted] = useState(isMuted);
  const [volume, setVolume] = useState(0.5);
  const [enablePip, setEnablePip] = useState(false);
  const [playerState, setPlayerState] = useState<BoltPlayerState>({
    played: 0,
    playedSeconds: 0,
    loaded: 0,
    loadedSeconds: 0,
  });

  useEffect(() => {
    setPlaying(true);
  }, [url]);

  const toggleMute = () => {
    if (volume === 0) {
      setMuted(false);
      setVolume(0.5);
    } else {
      setMuted(!muted);
    }
  };

  return (
    <FlexRow
      component={Box}
      sx={{
        flex: 1,
        maxHeight: isPopup ? "100vh" : "90vh",
        minHeight: isPopup ? "100vh" : "100%",
        minWidth: "100%",
        height: "auto",
        width: "100%",
        overflow: "hidden",
        position: "relative",
        bgcolor: "black",
        transition: "all 0.2s ease-out",
      }}
    >
      {isLive ? (
        <>
          <ReactPlayer
            ref={playerRef}
            className={className}
            style={{ display: "flex", marginTop: -6, transition: "all 0.8s ease" }}
            height="auto"
            width="100%"
            playsinline
            muted={muted}
            playing={playing}
            pip={enablePip}
            volume={volume}
            url={url!}
            loop
            onDisablePIP={() => setEnablePip(false)}
            onReady={() => setLoading(false)}
            onPlay={() => setPlaying(true)}
            onBuffer={() => setLoading(true)}
            onBufferEnd={() => setLoading(false)}
            onError={() => setLoading(false)}
            onProgress={setPlayerState}
            config={{
              file: {
                hlsOptions: {
                  xhrSetup: function xhrSetup(xhr: any, url: string) {
                    const newUrl = createOtteraChannelTrackUrl(url, { channelId, channelName: title });
                    xhr.open("GET", newUrl);
                  },
                },
              },
            }}
          />
          {playerRef.current && controls && isLive && (
            <PlayerControls
              isChatOpen={isSideChatBoxOpen}
              isPopup={isPopup}
              title={title!}
              description={title!}
              playing={playing}
              muted={muted as boolean}
              volume={volume}
              setPlaying={setPlaying}
              onChangeVolume={setVolume}
              togglePip={() => setEnablePip(true)}
              setMuted={setMuted}
              toggleMute={toggleMute}
              state={playerState}
              player={playerRef.current}
              type={isLive ? "live" : "normal"}
              handlePopout={handlePopout}
              handleChatClose={handleChatClose}
              isFloatingVideoPlayer={isFloatingPlayer}
              closeFloatingPlayer={closeFloatingPlayer}
              backToProfile={backToProfile}
            />
          )}
        </>
      ) : (
        <img
          src={StreamOffline}
          alt="Stream Offline"
          height="auto"
          width="100%"
          style={{ display: "flex", marginTop: -6 }}
        />
      )}
      <LoadingView visible={loading && isLive} sx={{ position: "absolute", zIndex: 1 }} />
    </FlexRow>
  );
};

export default React.memo(BoltPlayer);
