import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";

import { AboutTab, EntitySkeleton, FlexCol, FlexRow, ProfileHeader, TweetsTab, BoltPlayer } from "../../components";
import { ANALYTICS_TRACK_TYPE, CHANNEL_TYPE, PROFILE_MENU_ITEM, RECEIVER_TYPE } from "../../types";
import { Popup } from "../../utils";
import { channelSelector } from "../../store/channel/selector";
import { streamDataSelector } from "../../store/streamer/selector";
import { setStreamDataAction } from "../../store/streamer/actions";
import { useImmersivePanelLayoutContext } from "../../layouts";
import { defaultChatContainerId } from "../../constants";
import { useAnalyticsTracker } from "../../hooks";
import { EpisodesCarousel } from "../../components/Vod/EpisodesCarousel";

export const ChannelPage = () => {
  const dispatch = useDispatch();

  const { updateContext: updateImmersivePanelLayout } = useImmersivePanelLayoutContext();

  const { boltId } = useParams();

  const {
    channel: { data: channel },
  } = useSelector(channelSelector);

  const { url, isLive, title } = useSelector(streamDataSelector);

  const [currentTab, setCurrentTab] = useState(PROFILE_MENU_ITEM.about);
  const [isSideChatBoxOpen, setIsSideChatBoxOpen] = useState(true);

  useAnalyticsTracker({ entityId: channel?.id!, type: ANALYTICS_TRACK_TYPE.CHANNEL });

  useEffect(() => {
    updateImmersivePanelLayout({
      chatProps: {
        containerId: defaultChatContainerId,
        channelId: channel?.id || "",
        channelType: CHANNEL_TYPE.CHANNEL,
        receiverName: channel?.title || "",
        receiverType: RECEIVER_TYPE.CHANNEL,
      },
    });

    if (channel?.isLive) {
      dispatch(setStreamDataAction({ url: channel?.url, isLive: true, boltId, title: channel.title, isChannel: true }));
    } else {
      dispatch(setStreamDataAction({ url: undefined, isLive: false, boltId, isChannel: true }));
    }
  }, [channel]);

  if (!channel) {
    return (
      <div style={{ minWidth: "100%", position: "absolute", left: 0, top: 0 }}>
        <EntitySkeleton />
      </div>
    );
  }

  const handlePopout = () => {
    Popup.handleVideoPopup(boltId!);
  };
  const [currentEpisode, setCurrentEpisode] = useState<any>(null);
  const [currentIndex, setCurrentIndex] = useState<any>(0);
  const [currentId, setCurrentId] = useState<any>(null);
  const [vodArray, setVodArray] = useState<any[]>([]);

  useEffect(() => {
    if (channel) {
      if (channel?.vods && channel.vods.length > 0) {
        const channelArray =
          channel?.sourceType === "VOD"
            ? Array.isArray(channel.vods)
              ? channel.vods
              : []
            : [channel, ...(Array.isArray(channel.vods) ? channel.vods : [])];
        setVodArray(channelArray);
        setCurrentId(channel?.id);
      } else {
        setCurrentEpisode(null);
        setVodArray([]);
        setCurrentId(null);
      }
    }
  }, [channel]);

  // useEffect(() => {
  //   if (currentId) {
  //     const filterItem = vodVideos?.find((item: any) => item?.id === currentId);
  //     setCurrentEpisode(filterItem);
  //   }
  // }, [currentId]);

  useEffect(() => {
    if (currentIndex !== null && currentIndex !== undefined && vodArray?.length > 0) {
      setCurrentEpisode(vodArray?.[currentIndex]);
      setCurrentId(vodArray?.[currentIndex]?.id);
    }
  }, [currentIndex, vodArray]);

  return (
    <FlexCol sx={{ width: "100%", height: "auto" }}>
      <FlexRow>
        <FlexCol sx={{ width: "100%", transition: "all 300ms ease", position: "relative" }}>
          <FlexRow
            sx={{
              position: "relative",
              width: "100%",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "flex-start",
              overflow: "hidden",
            }}
          >
            <BoltPlayer
              url={currentEpisode?.hlsUrl ? currentEpisode?.hlsUrl : url}
              title={title}
              channelId={channel?.id}
              isLive={isLive}
              isSideChatBoxOpen={isSideChatBoxOpen}
              handleChatClose={() => setIsSideChatBoxOpen(true)}
              handlePopout={handlePopout}
            />
          </FlexRow>

          {vodArray?.length > 1 && (
            <EpisodesCarousel
              data={vodArray}
              setCurrentId={setCurrentId}
              currentId={currentId}
              setCurrentIndex={setCurrentIndex}
            />
          )}

          <Box sx={{ m: 0, mb: 4 }} alignItems="center" justifyContent="center">
            {/* {isXs && <ImmersivePanelDrawer />} */}

            <ProfileHeader
              channel={channel}
              enableChat={!!channel?.enableChat}
              openChatBox={() => {}}
              openGiftBox={() => {}}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              isUser={false}
            />

            <FlexCol sx={{ px: { xs: 2, sm: 3, md: 4, lg: 5 } }}>
              {currentTab === PROFILE_MENU_ITEM.tweets && (
                <TweetsTab id={channel?.id!} advertisement={channel?.advertisement} />
              )}

              {currentTab === PROFILE_MENU_ITEM.about && (
                <AboutTab
                  content={channel?.description}
                  twitter={channel?.twitterId}
                  instagram={channel?.instagramId}
                  website={channel?.website}
                  discord={channel?.discordId}
                />
              )}
            </FlexCol>
          </Box>
        </FlexCol>
      </FlexRow>
    </FlexCol>
  );
};
